<template>
    <!-- 尾部 -->
    <div class="tail">
        <div class="center">
            <div class="center_left"><span @click="go('user')">用户服务条款</span> | <span @click="go('privacy')">隐私说明</span></div>
            <div class="centerText">Copyright ◎ 2022-2024 长沙郊郊按摩有限公司 版权所有</div>
            <div class="center_right">
                <img src="@/assets/gongan.png" class="gongan" alt="">
                <a href="https://beian.miit.gov.cn/" target="_blank">湘公网安备  湘ICP备2024067323号-2</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
function go(val) {
    router.push({
      name: val
  });
}
</script>

<style scoped lang="less">
.center {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;

    .center_left {
      cursor: pointer;
        font-size: 16px;
        color: #FFFFFF;
    }

    .centerText {
        font-size: 16px;
        cursor: pointer;
        color: #FFFFFF;
    }

    .center_right {
        display: flex;
        align-items: center;

        .gongan {
            margin-right: 10px;
            display: block;
            width: 22px;
            height: 22px;
        }

        a {
            text-decoration: none;
            font-size: 16px;
            color: #FFFFFF;
        }
    }
}
</style>